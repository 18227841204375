<template>
	<div class="mt-3 mb-3 text-center"><button @click="mtd_iniciar" class="btn btn-primary">INICIAR EVALUACIÓN</button></div>
</template>
<script>
export default {
	methods: {
		mtd_iniciar: function () {
			this.$emit('emit-iniciar');
		},
	},
};
</script>
