<template>
	<div>
		<h4>Evaluación</h4>
		<!-- <h4>Evaluacion: {{ name_evaluation }}</h4> -->
		<p v-html="description"></p>
		<table class="table table-sm table-hover tablet-evaluations">
			<thead>
				<tr>
					<th style="width: 5%">ITEM</th>
					<th>PREGUNTA</th>
					<th style="width: 8%; text-align: center" v-for="(item, index) in options" :key="index">{{ item.description }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(question, iq) in questions" :key="iq">
					<th>{{ iq + 1 }}</th>
					<td v-html="question.description"></td>
					<td v-for="(option, io) in question.options" :key="io">
						<div class="form-check text-center">
							<input
								:name="question.postulant_question_id"
								:id="option.postulant_question_option_id"
								type="radio"
								:value="option.postulant_question_option_id"
								class="form-check-input form-check-input"
								v-model="question.selected" /><label :for="option.postulant_question_option_id" class="form-check-label"></label>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="text-center">
			<button @click="mtd_update" class="btn btn-primary btn-lg">ENVIAR</button>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: ['evaluation_select'],
	data() {
		return {
			name_evaluation: '',
			options: {},
			questions: {},
			postulant_sheet_id: null,
			postulant_evaluation_id: null,
			description: '',
		};
	},
	computed: {
		...mapGetters(['url_api']),
	},
	created() {
		this.mtd_initData();
	},
	methods: {
		...mapActions(['get', 'post', 'execute_commit', 'update', 'postWithFile']),
		mtd_initData: function () {
			this.name_evaluation = this.evaluation_select.evaluation.name;
			this.description = this.evaluation_select.evaluation.description;
			let url = null;
			if (this.evaluation_select.is_started) {
				url = '/sheet/' + this.evaluation_select.requirement_sheet_id + '/continue';
			} else {
				url = '/sheet/' + this.evaluation_select.requirement_sheet_id + '/start';
			}

			this.post({ url: `${this.url_api}${url}` }).then(res => {
				if (res.state == 'success') {
					this.options = res.sheet.evaluation.options;
					this.questions = res.sheet.evaluation.questions;
					this.postulant_sheet_id = res.sheet.postulant_sheet_id;
					this.postulant_evaluation_id = res.sheet.evaluation.postulant_evaluation_id;
				}
			});
		},
		mtd_update: function () {
			let count = 0;
			this.questions.forEach(element => {
				if (element.selected) {
					//
				} else {
					count = count + 1;
					return;
				}
			});
			if (count == 0) {
				this.$swal
					.fire({
						icon: 'warning',
						title: '¿Está seguro que desea enviar?',
						showCancelButton: true,
						confirmButtonText: 'Si, enviar',
					})
					.then(result => {
						if (result.isConfirmed) {
							this.post({
								url: `${this.url_api}/sheet/${this.evaluation_select.requirement_sheet_id}/update`,
								params: {
									postulant_sheet_id: this.postulant_sheet_id,
									postulant_evaluation_id: this.postulant_evaluation_id,
									questions: this.questions,
								},
							}).then(res => {
								if (res.state == 'success') {
									this.$emit('emit-store');
									this.$swal.fire({
										icon: 'success',
										title: 'Enviado',
										text: `Evaluación enviada correctamente.`,
										showCancelButton: false,
									});
								}
								if (res.state == 'exception') {
									window.simplealert('error', res.message, '<i class="fas fa-bomb"></i> Error interno');
								}
							});
						}
					});
			} else {
				this.$swal.fire({
					icon: 'warning',
					title: 'Comunicado',
					text: `Todas las preguntas son obligatorias.`,
					showCancelButton: false,
				});
			}
		},
	},
};
</script>
