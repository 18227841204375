<template>
	<div>
		<h4>Evaluacion Standar</h4>
		<p>Por favor responsa las siguientes preguntas con toda sinceridad.</p>
		<div v-for="e in [1, 2, 3, 4, 5, 6, 7]" :key="e" class="card mb-3">
			<div class="card-header bg-info text-white font-weight-bold py-2">
				Pregunta N° {{ e }}
				<!-- <div class="btn-group btn-group-sm ml-auto" role="group">
            <button type="button" class="btn btn-light btn-icon" v-tooltip="{content: 'Recargar pregunta' , class: 'tooltip-primary', placement: 'left'}"><i class="fas fa-redo-alt"></i></button>
          </div> -->
			</div>
			<div class="card-body text-secondary">
				<p class="card-text">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, repudiandae sunt rem quod enim vero provident eum soluta itaque dignissimos et iure eaque facere
					quo error officiis. Earum unde cumque harum quibusdam? Magni repellat blanditiis iure nulla a nisi placeat molestiae ab non praesentium vel quos, doloribus maiores rerum
					quis?
				</p>
				<template v-if="file != null">
					<div class="row justify-content-center mb-3">
						<div class="col-md-12 col-lg-10 text-center">
							<template v-if="file.type.includes('image')">
								<img :src="file.url" class="img-thumbnail" :alt="file.name" />
							</template>
							<template v-else-if="file.type.includes('audio')">
								<audio controls>
									<source :src="file.url" type="audio/ogg" />
									Tu navegador web no soporta este audio, por favor usa otro navegador web
								</audio>
							</template>
							<template v-else>
								<div class="card">
									<div class="card-body">
										<div class="text-center mb-1"><i @click="mtd_showFile(file)" class="far fa-file text-dark" style="font-size: 2.5em" role="button"></i></div>
										<p class="card-title text-body d-block" style="font-size: 0.775rem">{{ file.name }}</p>
									</div>
								</div>
							</template>
						</div>
					</div>
				</template>
				<!-- <div v-for="(opt, i) in [1, 2, 3, 4]" :key="i" class="custom-control custom-radio">
					<input type="radio" class="custom-control-input" name="question_options" :id="opt" />
					<label class="custom-control-label" for="1">Opcion</label>
				</div> -->
				<div class="row">
					<div class="col-md-12 d-flex justify-content-center">
						<div v-for="i in [1, 2, 3, 4, 5]" :key="i" class="custom-control custom-radio custom-control-inline">
							<input type="radio" id="customRadioInline1" name="customRadioInline" class="custom-control-input" />
							<label class="custom-control-label" for="customRadioInline1"> Opcion {{ i }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			file: null,
		};
	},
};
</script>
