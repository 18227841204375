<template>
	<div class="main-body p-0">
		<div class="inner-wrapper">
			<div class="inner-main">
				<page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
				<div class="inner-main-body">
					<div class="card card-featured card-featured-primary mb-2">
						<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<div class="progress" style="height: 22px">
										<div
											v-for="(item, index) in sheets"
											:key="index"
											class="progress-bar progress-bar-striped progress-bar-animated"
											:class="item.is_finished ? 'bg-success' : 'bg-secondary'"
											role="progressbar"
											:style="{ width: sheet_length + '%' }"
											aria-valuenow="33.33"
											aria-valuemin="0"
											aria-valuemax="100">
											<!-- item.evaluation.name -->
											{{ item.is_form == 1 ? item.form.name : 'Evaluación ' + index }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card card-featured card-featured-primary">
						<div class="card-body">
							<PersonalInformation v-if="view_select == 1" @emit-store="mtd_getSheets" :form_name="form_name" :requirement_sheet_id="requirement_sheet_id" :form_id="form_id" />
							<DobleEntryTable @emit-store="mtd_getSheets" v-if="view_select == 2" :evaluation_select="evaluation_select" />
							<StandardQuestionnaire v-if="view_select == 3" />
							<NoEvaluation v-if="view_select == 4" />
							<CargandoExamen @emit-iniciar="mtd_iniciarEvaluacion" v-if="view_select == 5" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)"> </modal>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import DobleEntryTable from './evaluation_types/DobleEntryTable';
import StandardQuestionnaire from './evaluation_types/StandardQuestionnaire';
import PersonalInformation from './evaluation_types/PersonalInformation';
import NoEvaluation from './evaluation_types/NoEvaluation.vue';
import CargandoExamen from './evaluation_types/CargandoExamen.vue';
export default {
	components: {
		PersonalInformation,
		DobleEntryTable,
		StandardQuestionnaire,
		NoEvaluation,
		CargandoExamen,
	},
	data() {
		return {
			filter_component: 'main-filter',
			executeFilter: 0,
			dataModel: {
				alias: 'Evaluaciones',
				icon: 'ri-file-copy-2-line',
			},
			page: {
				header: {
					menu: [],
				},
				buttons: {
					create: false,
					import: false,
					export: false,
				},
			},
			modals: [
				// {
				// 	id: 'test',
				// 	slot: {
				// 		component: null,
				// 		data: {
				// 			row: null,
				// 			buttonSubmit: null,
				// 			method: null,
				// 		},
				// 	},
				// 	config: {
				// 		title: null,
				// 		id: 'test',
				// 		idHash: '#test',
				// 		size: null,
				// 		scroll: true,
				// 		position: '',
				// 	},
				// },
			],
			sheets: [],
			evaluation_select: null,
			sheet_length: 0,
			view_select: 0,
			requirement_sheet_id: null,
			form_id: null,
			form_name: '',
		};
	},
	computed: {
		...mapGetters(['url_api']),
	},
	created() {
		this.mtd_getSheets();
	},
	methods: {
		...mapActions(['get', 'post', 'execute_commit', 'update']),
		mtd_getSheets: function () {
			this.get({ url: `${this.url_api}/requirement/${this.$route.params.evaluation_id}/list/sheets` }).then(res => {
				if (res.state == 'success') {
					this.sheets = res.sheets;
					this.sheet_length = 100 / res.sheets.length;
					this.evaluation_select = this.sheets.find(el => el.is_finished == 0);
					//let index = '';
					//index = this.evaluation_select = this.sheets.find((el,index) => el.is_finished == 0);
					if (this.evaluation_select) {
						if (this.evaluation_select.is_form) {
							if (this.evaluation_select.form.code == 'PERSONAL_INFORMATION') {
								this.view_select = 1;
								this.requirement_sheet_id = this.evaluation_select.requirement_sheet_id;
								this.form_id = this.evaluation_select.form.id;
								this.form_name = this.evaluation_select.form.name;
								//this.form_name = index;
							}
						}
						if (this.evaluation_select.is_evaluation) {
							this.view_select = 5;
						}
					} else {
						this.view_select = 4;
					}
				}
			});
		},
		mtd_iniciarEvaluacion: function () {
			if (this.evaluation_select.evaluation.evaluation_type_id == 1) {
				this.view_select = 2;
			}
			if (this.evaluation_select.evaluation.evaluation_type_id == 2) {
				this.view_select = 3;
			}
		},
	},
	beforeDestroy() {},
};
</script>
