<template>
	<div class="row form form-horizontal">
		<div class="col-md-12">
			<h4>Datos personales</h4>
		</div>
		<div class="col-md-12">
			<fieldset class="form-fieldset pb-0">
				<legend>Información personal</legend>
				<div class="row">
					<!-- TIPO DOCUMENTO -->
					<div class="form-group col-md-3">
						<label class="col-form-label label-modal" for="client_document_type_id">Documento</label>
						<label class="label-text">{{ postulant.document_number }}</label>
					</div>
					<!-- NOMBRE -->
					<div class="form-group col-md-8">
						<label class="col-form-label label-modal" for="name">Nombres y apellidos</label>
						<label class="label-text">{{ postulant.names }} {{ postulant.father_last_name }} {{ postulant.mother_last_name }}</label>
					</div>
					<!-- CELULAR -->
					<div class="form-group col-md-6">
						<label class="col-form-label label-modal" for="name">Celular <span class="text-danger">*</span></label>
						<input type="text" name="cellphone" v-validate="'required'" data-vv-as="'Celular'" v-model="personal_information.cellphone" class="form-control form-control-sm" />
						<span v-show="errors.has('cellphone')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('cellphone') }} </span>
					</div>
					<!-- CORREO -->
					<div class="form-group col-md-6">
						<label class="col-form-label label-modal" for="name">Correo <span class="text-danger">*</span></label>
						<input type="text" name="" value="" v-model="personal_information.email" class="form-control form-control-sm" />
					</div>
				</div>
			</fieldset>
		</div>
		<!-- INFORMACION ACADEMICA -->
		<div class="col-md-12">
			<fieldset class="form-fieldset pb-0">
				<legend>Información académica</legend>
				<div class="row">
					<div class="form-group col-md-3">
						<label class="col-form-label label-modal" for="name">Grado de instrucción <span class="text-danger">*</span></label>
						<select
							name="instruction_degree_id"
							v-validate="'required'"
							data-vv-as="'Grado de instrucción'"
							class="form-control form-control-sm"
							v-model="information_academic.instruction_degree_id">
							<option :value="null">Seleccionar</option>
							<option value="1">UNIVERSITARIO</option>
							<option value="2">TÉCNICO</option>
							<option value="3">SECUNDARIA COMPLETA</option>
						</select>
						<span v-show="errors.has('instruction_degree_id')" class="form-group__error"
							><i class="fas fa-exclamation-circle"></i> {{ errors.first('instruction_degree_id') }}
						</span>
					</div>
					<!-- CENTRO DE ESTUDIOS -->
					<div class="form-group col-md-3">
						<label class="col-form-label label-modal" for="name">Centro de estudios <span class="text-danger">*</span></label>
						<input
							type="text"
							name="name"
							v-validate="'required'"
							data-vv-as="'Centro de estudios'"
							v-model="information_academic.name"
							class="form-control form-control-sm text-uppercase" />
						<span v-show="errors.has('name')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('name') }} </span>
					</div>
					<!-- Carrera o curso -->
					<div class="form-group col-md-3">
						<label class="col-form-label label-modal text-uppercase" for="career_school">Carrera o curso <span class="text-danger">*</span></label>
						<input
							type="text"
							name="career_school"
							v-validate="'required'"
							data-vv-as="'Carrera o curso'"
							v-model="information_academic.career_school"
							class="form-control form-control-sm text-uppercase" />
						<span v-show="errors.has('career_school')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('career_school') }} </span>
					</div>
					<div class="form-group col-md-3">
						<label class="col-form-label label-modal" for="name">Estado <span class="text-danger">*</span></label>
						<select v-validate="'required'" data-vv-as="'Estado'" name="state" class="form-control form-control-sm" v-model="information_academic.state">
							<option :value="null">Seleccionar</option>
							<option value="1">EN CURSO</option>
							<option value="2">NO CONCLUIDO</option>
							<option value="3">CONCLUIDO</option>
						</select>
						<span v-show="errors.has('state')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('state') }} </span>
					</div>
					<div class="form-group col-md-12" v-if="information_academic.state == 1">
						<label class="col-form-label label-modal" for="name">Ciclo o año cursante</label>
						<input
							v-validate="'required'"
							data-vv-as="'Ciclo o año cursante'"
							type="text"
							name="observation"
							v-model="information_academic.observation"
							class="form-control form-control-sm text-uppercase" />
						<span v-show="errors.has('observation')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('observation') }} </span>
					</div>
					<div class="form-group col-md-12" v-if="information_academic.state == 2">
						<label class="col-form-label label-modal" for="name">Motivo de no concluido</label>
						<input
							v-validate="'required'"
							data-vv-as="'Motivo de no concluido'"
							type="text"
							name="observation"
							v-model="information_academic.observation"
							class="form-control form-control-sm text-uppercase" />
						<span v-show="errors.has('observation')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('observation') }} </span>
					</div>
					<div class="form-group col-md-12">
						<label class="col-form-label label-modal" for="name">Carga CV <span class="text-danger">PDF</span></label>
						<input class="form-control" @change="handleFileUpload()" type="file" name="file" id="file" ref="file" v-validate="'required|ext:pdf'" data-vv-as="'CV'" />
						<span v-show="errors.has('file')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('file') }} </span>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="form-group col-md-12 mt-3">
			<div class="form-check">
				<input v-model="terminos" id="customControlInline" type="checkbox" class="form-check-input form-check-input" /><label for="customControlInline" class="form-check-label"
					>Acepto la política de seguridad de información y los términos y condiciones de uso. <a class="text-primary" @click="mtd_openPoliticas">CLIC AQUÍ PARA LEER</a></label
				>
			</div>
		</div>
		<div class="col-md-12 text-center">
			<button :disabled="!terminos" @click="mtd_send('this.mtd_store()')" class="btn btn-primary btn-lg">ENVIAR</button>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: ['requirement_sheet_id', 'form_id', 'form_name'],
	data() {
		return {
			postulation_id: null,
			postulation_step_id: null,
			requirement_step_id: null,
			postulant: {},
			personal_information: {
				cellphone: '',
				email: '',
			},
			information_academic: {
				instruction_degree_id: null,
				name: '',
				career_school: '',
				state: null,
				observation: '',
			},
			file: null,
			terminos: false,
		};
	},
	computed: {
		...mapGetters(['url_api', 'url_web']),
	},
	created() {
		this.mtd_getPersonalInformation();
	},
	methods: {
		...mapActions(['get', 'post', 'execute_commit', 'update', 'postWithFile']),
		mtd_getPersonalInformation: function () {
			this.get({ url: `${this.url_api}/requirement/${this.$route.params.evaluation_id}/personal_information` }).then(res => {
				if (res.state == 'success') {
					this.postulant = res.postulant.person;
					this.personal_information.cellphone = res.postulant.cellphone;
					this.personal_information.email = res.postulant.email;
					this.postulation_id = res.postulation_id;
					this.postulation_step_id = res.postulation_step_id;
					this.requirement_step_id = res.requirement_step_id;
				}
			});
		},
		handleFileUpload() {
			let file_new = this.$refs.file.files;
			this.file = file_new[0];
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_send: function (method) {
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) _this.$eval(method);
			});
		},
		mtd_store() {
			this.$swal
				.fire({
					icon: 'warning',
					title: '¿Está seguro que desea enviar?',
					showCancelButton: true,
					confirmButtonText: 'Si, enviar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.formData = new FormData();
						this.formData.append('postulation_step_id', this.postulation_step_id);
						this.formData.append('requirement_sheet_id', this.requirement_sheet_id);
						this.formData.append('form_id', this.form_id);
						this.formData.append('postulation_id', this.postulation_id);
						this.formData.append('personal_information', JSON.stringify(this.personal_information));
						this.formData.append('information_academic', JSON.stringify(this.information_academic));
						this.formData.append('cv', this.file);
						this.postWithFile({
							url: `${this.url_api}/requirement/${this.$route.params.evaluation_id}/personal_information/update`,
							formData: this.formData,
						}).then(res => {
							if (res.state == 'success') {
								this.$emit('emit-store');
								this.$swal.fire({
									icon: 'success',
									title: 'Enviado',
									text: `Evaluación enviada correctamente.`,
									showCancelButton: false,
								});
							}
							if (res.state == 'exception') {
								window.simplealert('error', res.message, '<i class="fas fa-bomb"></i> Error interno');
							}
						});
					}
				});
		},
		mtd_openPoliticas: function () {
			window.open(`${this.url_web}/uploads/documents/TERMINOS_Y_CONDICIONES_DE_USO_DE_PLATAFORMA.pdf`);
		},
	},
};
</script>
